<template>
  <modal
    :name="modalName"
    adaptive
    width="90%"
    height="90%"
    :scrollable="true"
    class="compare--modal"
  >
    <div class="modal--wrapper">
      <section class="modal--container">
        <div class="d-flex justify-content-around align-items-center mt-3">
          <div class="first--col"></div>
          <div class="wrapper--content mb-3" v-for="project in projects" :key="project.key">
            <div class="wrapper--image">
              <img
                v-if="project && project.primary_photo"
                class="img-responsive"
                :src="project.primary_photo.url"
                :alt="project.project_title"
              />
              <img
                v-else
                class="img-responsive"
                src="/img/fallback_image.jpg"
                :alt="project.project_title"
              />
            </div>
            <div class="project--title text-center">{{ project.project_title }}</div>
          </div>
        </div>
        <div class="wrapper--info" v-if="projects">
          <!-- row for detail price -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.price') }}
            </div>
            <div class="wrapper--detail text-center" v-for="project in projects" :key="project.key">
              <div v-if="project.type === 'R'">
                <div v-for="rentPrice in project.rent_prices" :key="rentPrice.key">
                  {{ rentPrice.price_terbilang }}
                </div>
              </div>
              <div v-else>
                {{ $n(project.sell_price, 'currency', 'id-ID') }}
              </div>
            </div>
          </div>
          <!-- row for detail installment -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.installment') }}
            </div>
            <div class="wrapper--detail text-center" v-for="project in projects" :key="project.key">
              <div v-if="project.cicilan_gradana">
                <span>
                  Rp{{ project.cicilan_gradana.sixMonthsInstallment }} /
                  {{ $t('fintechInstallment.formCicilan.6m') }}<br />
                  Rp{{ project.cicilan_gradana.oneYearInstallment }} /
                  {{ $t('fintechInstallment.formCicilan.1year') }}<br />
                  Rp{{ project.cicilan_gradana.twoYearInstallment }} /
                  {{ $t('fintechInstallment.formCicilan.2year') }}<br />
                </span>
              </div>
              <div v-else>
                {{ $t('fintechInstallment.formCicilan.notAvailable') }}
              </div>
            </div>
          </div>
          <!-- row for detail property type -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.propertyType') }}
            </div>
            <div class="wrapper--detail text-center" v-for="project in projects" :key="project.key">
              {{ project.property.property_type_name }}
            </div>
          </div>
          <!-- row for detail address -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.address') }}
            </div>
            <div class="wrapper--detail text-center" v-for="project in projects" :key="project.key">
              {{ project.property.address_line_1 }}&nbsp;
              {{ project.property.address_line_2 }}
            </div>
          </div>
          <!-- row for detail address -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.rentType') }}
            </div>
            <div class="wrapper--detail text-center" v-for="project in projects" :key="project.key">
              {{ project.rent_type }}
            </div>
          </div>
          <!-- row for detail size -->
          <div class="info--row">
            <div class="first--col">
              {{ $t('general.compare.buildingSize') }}
            </div>
            <div class="wrapper--detail text-center" v-for="project in projects" :key="project.key">
              {{ project.size_in_square_meters }} m<sup>2</sup>
            </div>
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'compareModal',
  computed: {
    ...mapState({
      projects: (state) => state.v2.project.compareProjects,
    }),
  },
  data: () => ({
    modalName: 'modal-compare',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
